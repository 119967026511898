<template>
  <div class="invite-view">
    <CategoryTitle :category="category" />
    <v-row no-gutters class="invitation flex-column align-center mx-auto">
      <v-col class="invitation-how-to pa-5 mb-5 col-sm-9">
        <h6 class="invitation-how-to-title mb-4">
          <span class="text-uppercase">invita i tuoi amici!</span> Per te e per
          loro subito <span class="primary--text"><strong>10€</strong></span> di
          sconto sulla spesa online
        </h6>
        <p class="invitation-how-to-description text-small">
          Trovi che la <strong>spesa online</strong>con il servizio
          <strong>Consegna a Casa</strong>o con il servizio
          <strong>Clicca e Ritira </strong>sia comoda e funzionale?
        </p>
        <p class="invitation-how-to-description text-small">
          <strong>Invita i tuoi amici</strong> a provarlo e riceverete entrambi
          un <strong>buono sconto</strong>
          da utilizzare per la vostra spesa! Scopri com'è semplice!
        </p>
        <v-row no-gutters class="invitation-how-to-procedure mt-10 flex-column">
          <v-col
            cols="12"
            class="invitation-how-to-procedure-wrapper d-flex flex-column justify-center align-center"
          >
            <div
              class="invitation-how-to-procedure-wrapper__images d-flex justify-space-between w-100"
            >
              <img
                class="svg-icons col-4 pa-2"
                src="../../../public/img_layout/invite/01.svg"
                alt="primo step"
              />
              <img
                class="svg-icons col-4 pa-2"
                src="../../../public/img_layout/invite/02.svg"
                alt="secondo step"
              />
              <img
                class="svg-icons col-4 pa-2"
                src="../../../public/img_layout/invite/03.svg"
                alt="terzo step"
              />
            </div>
            <ul
              class="invitation-how-to-procedure-wrapper__information d-flex w-100 justify-space-between my-3"
            >
              <li class="col-4 pa-2 d-flex flex-column align-center">
                <span class="rounded-circle text-center step-number mb-3"
                  ><strong>1</strong></span
                >
                <span class="text-uppercase text-center"
                  ><strong>{{ $t("invite.step.1") }}</strong></span
                >
              </li>
              <li class="col-4 pa-2 d-flex flex-column align-center">
                <span class="rounded-circle text-center step-number mb-3"
                  ><strong class="d-flex justify-center align-center"
                    >2</strong
                  ></span
                >
                <span class="text-uppercase text-center">
                  <strong>{{ $t("invite.step.2") }}</strong>
                </span>
              </li>
              <v-divider class="mx-0"></v-divider>
              <li class="col-4 pa-2 d-flex flex-column align-center">
                <span class="rounded-circle text-center step-number mb-3"
                  ><strong>3</strong></span
                >
                <span class="text-uppercase text-center"
                  >{{ $t("invite.step.3") }}<br /><span
                    class="primary--text body-1"
                    ><strong>10€</strong></span
                  ></span
                >
              </li>
            </ul>
          </v-col>
          <v-col cols="12" class="d-flex justify-center align-center mt-5">
            <img
              class="social-icons mx-5"
              src="../../../public/img_layout/invite/envelope.png"
              alt="invia via mail"
            />
            <img
              class="social-icons mx-5"
              src="../../../public/img_layout/invite/link.png"
              alt="copia codice"
            />
            <!-- <img
              class="social-icons mx-5"
              src="../../../public/img_layout/social/facebook-icon.png"
              alt="invia via facebook"
            /> -->
            <img
              class="social-icons mx-5"
              src="../../../public/img_layout/social/whatsapp.png"
              alt="invia via whatsapp"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col class="invitation-discount-code py-5 col-sm-9">
        <v-row no-gutters class="flex-column text-center">
          <v-col class="d-sm-flex align-center justify-center"
            ><span class="text-uppercase d-block mb-4 mb-sm-0 mr-sm-4">{{
              $t("invite.code.title")
            }}</span>
            <span class="pa-2 warning base primary--text" v-if="personalCode"
              ><strong>{{ personalCode.tellFriend.code }}</strong></span
            >
          </v-col>

          <v-col class="d-flex justify-center align-center mt-4"
            ><span class="col-6 col-sm-3 pa-0 text-small"
              ><strong>{{ $t("invite.text") }}</strong></span
            >
            <div class="col-6 pa-0 d-flex justify-space-around align-center">
              <v-btn @click="mailSharingUrl" text>
                <img
                  class="social-icons"
                  src="../../../public/img_layout/invite/envelope.png"
                  alt="invia via mail"
                />
              </v-btn>
              <v-btn @click="copyCode" text>
                <img
                  class="social-icons"
                  src="../../../public/img_layout/invite/link.png"
                  alt="copia codice"
                />
              </v-btn>

              <!-- 
                <v-btn @click="shareViaFacebook" text>
                <img
                class="social-icons"
                src="../../../public/img_layout/social/facebook-icon.png"
                alt="invia via facebook"
              />
              </v-btn>
               -->
              <v-btn @click="shareViaWhatsapp" text>
                <img
                  class="social-icons"
                  src="../../../public/img_layout/social/whatsapp.png"
                  alt="invia via whatsapp"
                />
              </v-btn></div
          ></v-col>
        </v-row>
      </v-col>
      <v-col class="invitation-active-code col-sm-9">
        <v-row no-gutters class="flex-column my-5">
          <v-col
            class="d-flex flex-column flex-sm-row justify-sm-space-between align-center align-sm-start pa-0"
            ><img
              class="svg-icons mb-4 mr-sm-4"
              src="../../../public/img_layout/invite/man.svg"
              alt=""
            />
            <div>
              <h6 class="text-uppercase text-center text-sm-left mb-1">
                {{ $t("invite.gifts.header") }}
              </h6>
              <p class="text-small ma-0">
                Al primo acquisto di ogni tuo invitato, riceverai un buono
                sconto di 10€.
              </p>
              <p class="text-small">
                I tuoi amici riceveranno il loro buono sconto da 10€ subito dopo
                la registrazione.
              </p>
            </div>
          </v-col>
          <v-col class="pa-0">
            <h6 class="text-center my-2">
              Attualmente hai
              <span class="primary--text"><strong>0</strong></span> buoni attivi
            </h6>
            <p class="caption">
              Il buono verrà <strong>applicato automaticamente</strong> alla
              prima spesa utile sul servizio di Consegna a Casa o sul servizio
              Clicca e Ritira.
            </p>
            <p class="caption mb-0">
              (Buoni sconto erogabili fino ad un massimo di 20 per utente. Per
              ottenere il buono sconto l’amico invitato dovrà iscriversi per la
              prima volta al servizio e fare la spesa. I buoni non sono
              cumulabili)
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <ebsn-meta
      :target="category"
      path="category_info.FOOTER_DESCRIPTION"
      tag="div"
    />
  </div>
  <!-- CODICE SCONTO -->
  <!--
    <v-row
      no-gutters
      class="personal-code pt-4 pb-11 default--text"
      justify="center"
      align="center"
      v-if="code"
    >
      <v-col cols="12" class="text-center">
        <h3 class="code-title">
          {{ $t("invite.code.title") }}
        </h3>
        <v-row
          no-gutters
          class="text-h3 font-weight-bold code-box rounded-sm py-3 mt-4 mx-auto"
          align="center"
        >
          <v-col cols="2">&nbsp;</v-col>
          <v-col cols="8" class="text-h2">{{
            personalCode.tellFriend.code
          }}</v-col>
          <v-col cols="2">
            <v-icon large @click="copyCode">$copy</v-icon>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-divider />

    <!- - BUONI SCONTO - ->
    <v-row no-gutters class="gift-certificates pt-7" v-if="giftCertificates">
      <v-col cols="12" class="text-center">
        <h3 class="gifts-title">
          {{ $t("invite.gifts.title") }}
        </h3>
        <v-row
          no-gutters
          class="count-box text-h3 font-weight-bold rounded-sm py-6 mt-4 mx-auto default--text"
          align="center"
        >
          <v-col cols="12">
            <div class="count font-weight-bold">
              {{ giftCertificates.length }}
            </div>
            <div class="text-h4">
              {{ $tc("invite.gifts.friends", giftCertificates.length) }}
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row no-gutters class="list my-11">
      <v-list dense class="w-100">
        <v-list-item v-for="(item, idx) in giftCertificates" :key="idx">
          <v-row justify="space-between" class="px-4">
            <div>{{ `${item.name} (${$n(item.amount, "currency")})` }}</div>
            <div>{{ formatEndDate(item.endDate) }}</div>
          </v-row>
        </v-list-item>
      </v-list>
    </v-row>

    <v-divider />-->
</template>
<style lang="scss" scoped>
.invitation {
  max-width: 750px;
  .text-small {
    font-size: 0.8125rem;
  }
  &-how-to {
    background-color: var(--v-warning-lighten1);
    &-procedure {
      &-wrapper__information {
        position: relative;
        .v-divider {
          position: absolute;
          border: 2px solid var(--v-primary-base);
          height: 0.2em;
          top: 16px;
          width: 70%;
          left: 15%;
        }
        li {
          .step-number {
            width: 20px;
            color: white;
            font-size: 13px;
            background-color: var(--v-primary-base);
            z-index: 1;
          }
          > span:nth-of-type(2) {
            font-size: 8px;
          }
        }
      }
    }
  }
  &-discount-code {
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
  }
  .social-icons {
    width: 16px;
    height: 16px;
  }
  .svg-icons {
    max-height: 65px;
  }
}

/*.invite-container {
  .invite-card {
    .v-image {
      @media #{map-get($display-breakpoints, 'xs-only')} {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
    .desc {
      div {
        line-height: 22px;
        @media #{map-get($display-breakpoints, 'xs-only')} {
          line-height: 18px;
        }
      }
      .ul-classes {
        margin: 20px 0px;
        .li-classes {
          list-style: disc !important;
          margin-left: 25px;
        }
      }
    }
  }
  .personal-code {
    .code-box {
      border: 2px solid var(--v-primary-lighten1);
      max-width: 330px;
      .v-image {
        cursor: pointer;
      }
    }
  }
  .gift-certificates {
    .count-box {
      border: 1px solid $border-color;
      max-width: 330px;
      .count {
        font-size: 41px;
      }
    }
  }

  .list {
    .v-list-item:nth-child(odd) {
      background: var(--v-primary-lighten2);
    }
  }

  .instructions {
    .instruction-box {
      border: 1px solid var(--v-grey-base);
      border-radius: $border-radius-root;
      padding: 24px;
      color: $primary;
      min-height: 240px;
      height: 100%;
      background-color: var(--v-primary-lighten2);
      @media #{map-get($display-breakpoints, 'sm-only')} {
        min-height: 330px;
      }
      .text-h4 {
        line-height: 28px;
        min-height: 75px;
      }
    }
  }

  .uses-clause {
    .li-classes {
      list-style: disc !important;
      margin-left: 25px;
    }
  }
}*/
</style>

<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";

import categoryMixins from "~/mixins/category";

import InviteService from "~/service/inviteService";

import get from "lodash/get";
import { mapGetters } from "vuex";

export default {
  name: "Invite",
  components: { CategoryTitle },
  mixins: [categoryMixins],
  data() {
    return {
      personalCode: null,
      instructions: null
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    }),
    catDescr() {
      return get(this.category, "metaData.category_info.DESCRIPTION");
    },
    code() {
      return (
        this.personalCode &&
        this.personalCode.tellFriend &&
        this.personalCode.tellFriend.code
      );
    },
    giftCertificates() {
      // let array = [
      //   {
      //     name: "Buono Sconto 5€",
      //     endDate: "01/05/2022"
      //   },
      //   {
      //     name: "Buono Sconto 5€",
      //     endDate: "05/05/2022"
      //   },
      //   {
      //     name: "Buono Sconto 5€",
      //     endDate: "07/06/2022"
      //   },
      //   {
      //     name: "Buono Sconto 5€",
      //     endDate: "15/09/2022"
      //   }
      // ];
      // if (this.personalCode && this.personalCode.userGiftCertificates) {
      //   array.concat(
      //     this.personalCode && this.personalCode.userGiftCertificates
      //   );
      // }
      // return array;
      if (this.personalCode && this.personalCode.userGiftCertificates) {
        return this.personalCode && this.personalCode.userGiftCertificates;
      }
      return [];
    },
    usesClauseList() {
      let list = this.geti18nObject("invite.usesClause.lists");
      return list;
    },
    cardList() {
      let list = this.geti18nObject("invite.card.lists");
      return list;
    }
  },
  methods: {
    copyCode() {
      if (this.$platform_is_cordova && window.cordova.plugins.clipboard) {
        window.cordova.plugins.clipboard.copy(
          this.code,
          global.EventBus.$emit("success", {
            message: global.EventBus.$t("invite.code.copied")
          })
        );
      } else {
        navigator.clipboard.writeText(this.code);
        global.EventBus.$emit("success", {
          message: global.EventBus.$t("invite.code.copied")
        });
      }
    },
    shareViaFacebook() {
      window.open(
        "https://www.facebook.com/dialog/feed?display=popup&app_id=" +
          this._facebookClientId +
          "&description=" +
          this.code,
        "_blank"
      );
    },
    shareViaWhatsapp() {
      let text =
        this.user.firstName +
        " ti ha inviato un codice sconto che puoi utilizzare sulla tua prima spesa online su Basko.it, inserisci il codice " +
        this.code +
        ".";
      if (this.$platform_is_cordova) {
        window.cordova.InAppBrowser.open(
          "https://api.whatsapp.com/send/?phone&text=" + text,
          "_system"
        );
      } else {
        window.open(
          "https://api.whatsapp.com/send/?phone&text=" + text,
          "_self"
        );
      }
    },
    mailSharingUrl() {
      let subject = this.user.firstName + " ti ha inviato un codice sconto!";
      let body =
        this.user.firstName +
        " ti ha inviato un codice sconto che puoi utilizzare sulla tua prima spesa online su Basko.it, inserisci il codice " +
        this.code +
        ".";
      if (this.$platform_is_cordova) {
        window.cordova.InAppBrowser.open(
          "mailto:?subject=" + subject + "&body=" + body,
          "_system"
        );
      } else {
        window.open("mailto:?subject=" + subject + "&body=" + body, "_self");
      }
    },
    loadInstructionSteps() {
      this.instructions = [
        {
          title: "Ottieni il codice sconto",
          description:
            "Ottieni il codice sconto nella sezione dedicata di questa pagina."
        },
        {
          title: "Condividilo con i tuoi amici",
          description:
            "Utilizza i canali che preferisci, come WhatsApp e Facebook, per inviare ai tuoi amici il codice sconto."
        },
        {
          title: "Ricevi il buono sconto a te dedicato",
          description:
            "Per ogni amico che effettua la sua prima spesa online ricevi una mail di notifica e uno sconto del 10% che potrai utilizzare per il tuo prossimo ordine."
        }
      ];
    },
    formatEndDate(date) {
      return this.$dayjs(date).format("D/MM/YYYY");
    }
  },
  async created() {
    await this.loadInstructionSteps();
    let response = await InviteService.getPersonalCode();
    if (response) {
      this.personalCode = response;
    }
  }
};
</script>
